<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>実績レポート</CCardHeader>
          <CCardBody>
            <h6>絞り込み</h6>
            <CForm>
              <CRow>
                <CCol md="6">
                  <CInput label="クーポン番号（クーポンIDの下6桁）" placeholder="" v-model="form.couponCode"
                    :isValid="checkCode()"
                    invalidFeedback="JANコードとクーポン番号（クーポンIDの下6桁）は両方設定してください。"
                  />
                </CCol>
                <CCol md="6">
                  <CInput label="JANコード" placeholder="" v-model="form.janCode" 
                    :isValid="checkCode()"
                    invalidFeedback="JANコードとクーポン番号（クーポンIDの下6桁）は両方設定してください。"
                  />
                </CCol>
              </CRow>
              <CRow v-if="isAdmin()">
                <CCol>
                  <h6>メーカー、卸、レジ、小売 いずれか1つ入力してください。</h6>
                </CCol>
              </CRow>
              <CRow v-if="isAdmin()">
                <CCol>
                  <CSelect
                    label="メーカー"
                    horizontal
                    :options="makerPullDown"
                    :value.sync="form.makerId"
                    @update:value="onUpdateMaker($event)"
                  />
                </CCol>
                <CCol>
                  <CSelect
                    label="卸"
                    horizontal
                    :options="wholesalePullDown"
                    :value.sync="form.wholesaleId"
                    @update:value="onUpdateWholesale($event)"
                  />
                </CCol>
              </CRow>
              <CRow v-if="isAdmin()">
                <CCol sm="6">
                  <CSelect
                    label="レジ"
                    horizontal
                    :options="registerPullDown"
                    :value.sync="form.registerId"
                    @update:value="onUpdateRegister($event)"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput label="小売コード" horizontal placeholder="" v-model="form.supplierId" />
                </CCol>
              </CRow>
              <CRow v-if="isDistribution()">
                <CCol>
                  <h6>メーカー、卸 はどちらかのみ入力してください。</h6>
                </CCol>
              </CRow>
              <CRow v-if="isDistribution()">
                <CCol>
                  <CSelect
                    label="メーカー"
                    horizontal
                    :options="makerPullDown"
                    v-model="form.makerId"
                    @update:value="onUpdateMaker($event)"
                  />
                </CCol>
                <CCol>
                  <CSelect
                    label="卸"
                    horizontal
                    :options="wholesalePullDown"
                    placeholder="Please select"
                    :value.sync="form.wholesaleId"
                    @update:value="onUpdateWholesale($event)"
                  />
                </CCol>
                <CCol>
                  <CSelect
                    label="店舗"
                    horizontal
                    :options="storePullDown"
                    placeholder="Please select"
                    :value.sync="form.storeCode"
                    @update:value="onUpdateStore($event)"
                  />
                </CCol>
              </CRow>
              <CRow v-if="this.isMakers()">
                <CCol md="6">
                  <CSelect
                    label="小売"
                    horizontal
                    :options="distributionPullDown"
                    :value.sync="form.companyCode"
                    @update:value="onUpdateDistribution($event)"
                  />
                </CCol>
                <CCol md="6">
                  <CSelect
                    label="店舗"
                    horizontal
                    :options="storePullDown"
                    :value.sync="form.storeCode"
                    @update:value="onUpdateStore($event)"
                  />
                </CCol>
              </CRow>
              <CRow v-if="this.isWholeSales()">
                <CCol md="6">
                  <CSelect
                    label="小売"
                    horizontal
                    :options="distributionPullDown"
                    :value.sync="form.companyCode"
                    @update:value="onUpdateDistribution($event)"
                  />
                </CCol>
                <CCol md="6">
                  <CSelect
                    label="メーカー"
                    horizontal
                    :options="makerPullDown"
                    :value.sync="form.makerId"
                    @update:value="onUpdateMaker($event)"
                  />
                </CCol>
              </CRow>
              <CRow v-if="isRegister()">
                <CCol>
                  <CSelect
                    label="メーカー"
                    horizontal
                    :options="makerPullDown"
                    :value.sync="form.makerId"
                    @update:value="onUpdateMaker($event)"
                  />
                </CCol>
                <CCol>
                  <CSelect
                    label="小売ブランド"
                    horizontal
                    :options="distributionPullDown"
                    :value.sync="form.companyCode"
                    @update:value="onUpdateDistribution($event)"
                  />
                </CCol>
                <CCol>
                  <CSelect
                    label="店舗"
                    horizontal
                    :options="storePullDown"
                    :value.sync="form.storeCode"
                    @update:value="onUpdateStore($event)"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CInput label="期間（開始）" type="date" v-model="form.startDate" />
                </CCol>
                <CCol md="6">
                  <CInput label="期間（終了）" type="date" v-model="form.endDate" />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                    <div class="form-group">
                      <div class="py-1">取得形式</div>
                      <div class="form-group form-row col-sm-10">
                          <div role="group" class="col-sm-3 form-check">
                          <input type="radio" v-model="form.downloadType" value="1" name="dlType" class="form-check-input" id="dlType1">
                              <label for="dlType1" class="form-check-label"> 一覧 </label>
                          </div>
                          <div role="group" class="col-sm-3 form-check">
                              <input type="radio" v-model="form.downloadType" value="2" name="dlType" class="form-check-input" id="dlType2">
                              <label for="dlType2" class="form-check-label"> 集計 </label>
                          </div>
                      </div>
                    </div>
                </CCol>
              </CRow>
              <br />
              <h6>ソート</h6>
              <CRow>
                <CCol md="6">
                  <CSelect
                    :options="options"
                    :value.sync="form.sort"
                    @update:value="onUpdateSort($event)"
                    />
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <CCardFooter>
            <CRow>
              <CCol md="6">
                <CButton type="submit" color="info" class="w-50" @click="downloadCSV()">
                  ダウンロード
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import {UserRole} from '@/common/const';
import firebase from '@firebase/app';

export default {
  name: "Report",
  data() {
    return {
      form: {
          couponCode: "",
          janCode: "",
          makerId: "",
          supplierId: "",
          wholesaleId: "",
          registerId: "",
          companyCode: "",
          storeCode: "",
          startDate: "",
          endDate: "",
          downloadType: "1",
          sort: "1",
      },
      show: true,
      label: "col-3",
      input: "col-9",
      options: [
        { value: 1, label: "日付（降順）" },
        { value: 2, label: "日付（昇順）" },
      ],
      coupons: {},
      usages: {},
      users: {},
      stores: {},
      makers: {},
      wholesales: {},
      account: {},
      formCollapsed: true,
      makerPullDown: [],
      wholesalePullDown: [],
      registerPullDown:[],
      distributionPullDown:[],
      storePullDown:[],
    };
  },
  methods: {
    async init() {
      let uid
      uid = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(uid).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        uid = parent_id
      }
      let event = firebase.app().functions('asia-northeast1').httpsCallable('me')
      this.$store.commit("showLoading")
      // ユーザーデータ取得
      await event({uid: uid}).then(function(res) {
        this.account = res.data.user
        this.$store.commit("hideLoading")
      }.bind(this));

      var users = firebase.firestore().collection("users").where("role", "==", UserRole.DISTRIBUTIONS);
      var stores = firebase.firestore().collection("stores");
      var makers = firebase.firestore().collection("users").where("role", "==", UserRole.MAKERS);
      var wholesales = firebase.firestore().collection("users").where("role", "==", UserRole.WHOLESALE);
      await users.get().then(
          function(result) {
              let tmp = {}
              result.forEach(function(doc) {
                  tmp[doc.data().companyCode] = doc.data().name;
              });
              this.users = tmp;
          }.bind(this)
      );
      await stores.get().then(
          function(result) {
              let tmp = {}
              result.forEach(function(doc) {
                  if (tmp[doc.data().companyCode] == undefined) {
                      tmp[doc.data().companyCode] = {}
                  }
                  tmp[doc.data().companyCode][doc.data().storeCode] = doc.data().name;
              });
              this.stores = tmp;
          }.bind(this)
      );
      await makers.get().then(
          function(result) {
              let tmp = {}
              result.forEach(function(doc) {
                  tmp[doc.data().id] = doc.data().name;
              });
              this.makers = tmp;
          }.bind(this)
      );
      await wholesales.get().then(
          function(result) {
              let tmp = {}
              result.forEach(function(doc) {
                  tmp[doc.data().id] = doc.data().name;
              });
              this.wholesales = tmp;
          }.bind(this)
      );
      // プルダウン用のメーカー一覧取得
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.MAKERS)
        .get()
        .then(
          function(result) {
            let tmp = [];
            tmp.push({ label: "", value: "" });
            result.forEach(function(doc) {
              if (doc.data().parent_id == undefined) {
                tmp.push({ label: doc.data().name, value: doc.data().id });
              }
            });
            this.makerPullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
          }.bind(this)
        );
      // プルダウン用の卸一覧取得
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.WHOLESALE)
        .get()
        .then(
          function(result) {
            let tmp = [];
            tmp.push({ label: "", value: "" });
            result.forEach(function(doc) {
              if (doc.data().parent_id == null || doc.data().parent_id == "") {
                tmp.push({ label: doc.data().name, value: doc.data().id });
              }
            });
            this.wholesalePullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
          }.bind(this)
        );
      // プルダウン用のレジ一覧取得
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.REGISTER)
        .get()
        .then(
          function(result) {
            let tmp = [];
            tmp.push({ label: "", value: "" });
            result.forEach(function(doc) {
              tmp.push({ label: doc.data().name, value: doc.data().id });
            });
            this.registerPullDown = tmp;
          }.bind(this)
        );
      // プルダウン用の店舗一覧取得
      if (this.isDistribution()) {
        firebase
          .firestore()
          .collection("stores")
          .where("companyCode", "==", this.account.companyCode)
          .get()
          .then(
            function(result) {
              let tmp = [];
              tmp.push({ label: "", value: "" });
              result.forEach(function(doc) {
                if (doc.data().parent_id == null || doc.data().parent_id  == "") {
                  tmp.push({ label: doc.data().name, value: ("000000" + doc.data().storeCode).slice(-6) });
                }
              });
              this.storePullDown = tmp.sort((x, y) => Number(x.value) - Number(y.value));
            }.bind(this)
          );
      }
      if (this.isRegister()) {
        const storeSnapshot = await firebase.firestore().collection("stores")
          .where("register_id", "==", this.account.id).get();
        let tmp = [];
        tmp.push({ label: "", value: "" });
        for (const storeDoc of storeSnapshot.docs) {
          const store = storeDoc.data()
          const distributionSnapshot = await firebase.firestore().collection("users")
            .where("companyCode", "==", store.companyCode).get();
          for (const doc of distributionSnapshot.docs) {
            const companyCode = ("000000" + doc.data().companyCode).slice(-6);
            const parentId = doc.data().parent_id;
            if ((parentId == null || parentId == "") && !tmp.some(v => v.value == companyCode)) {
              tmp.push({ label: doc.data().name, value: companyCode });
            }
          }
        }
        this.distributionPullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
      } else {
        // プルダウン用の小売一覧取得
        let tmp = [];
        tmp.push({ label: "", value: "" });
        const distributionSnapshot = await firebase.firestore().collection("users").where("role", "==", UserRole.DISTRIBUTIONS).get()
        for (const doc of distributionSnapshot.docs) {
          const companyCode = ("000000" + doc.data().companyCode).slice(-6);
          const parentId = doc.data().parent_id;
          if ((parentId == null || parentId == "") && !tmp.some(v => v.value == companyCode)) {
            tmp.push({ label: doc.data().name, value: companyCode });
          }
        }
        this.distributionPullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
      }
    },
    checkCode() {
        const form = this.form;
        if (form.janCode) {
            if (form.couponCode) {
                return null;
            } else {
                return false;
            }
        }
        if (form.couponCode) {
            if (form.janCode) {
                return null;
            } else {
                return false;
            }
        }
    },
    onUpdateType(value) {
      this.form.downloadType = value;
    },
    onUpdateSort(value) {
      this.form.sort = value;
    },
    onUpdateMaker(value) {
      this.form.makerId = value;
    },
    onUpdateWholesale(value) {
      this.form.wholesaleId = value;
    },
    async onUpdateDistribution(value) {
      this.form.companyCode = value;
      // プルダウン用の店舗一覧取得
      if (!this.isWholeSales()) {
        this.storePullDown = [];
        this.storePullDown.push({ label: "", value: "" });
        this.form.storeCode = null;
        if (value != "") {
          const storeSnapshit = await firebase.firestore().collection("stores").where("companyCode", "==", Number(value)).get();
          for (const doc of storeSnapshit.docs) {
            const storeCode = ("000000" + doc.data().storeCode).slice(-6);
            this.storePullDown.push({ label: doc.data().name, value: storeCode });
          }
        }
        this.storePullDown.sort((x, y) => Number(x.value) - Number(y.value));
      }
    },
    onUpdateStore(value) {
      this.form.storeCode = value;
    },
    onUpdateRegister(value) {
      this.form.registerId = value;
    },
    isAdmin() {
      return this.account.role == UserRole.ADMIN;
    },
    isDistribution() {
      return this.account.role == UserRole.DISTRIBUTIONS;
    },
    isMakers() {
      return this.account.role == UserRole.MAKERS;
    },
    isWholeSales() {
      return this.account.role == UserRole.WHOLESALE;
    },
    isRegister() {
      return this.account.role == UserRole.REGISTER;
    },
    async downloadCSV () {
      var form = this.form

      if (this.account.role == UserRole.MAKERS) {
        form.makerId = this.account.id;
      } else if (this.account.role == UserRole.DISTRIBUTIONS) {
        form.supplierId = ("000000" + this.account.companyCode).slice(-6);
      } else if (this.account.role == UserRole.WHOLESALE) {
        form.wholesaleId = this.account.id;
      } else if (this.account.role == UserRole.REGISTER) {
        form.registerId = this.account.id;
      }

      var _coupons = firebase.firestore().collection("coupons");
      var _usages = firebase.firestore().collection("usages");
      
      var coupons_where = _coupons;
      let registerStores = []

      if (form.makerId) {
        coupons_where = coupons_where.where("maker_id", "==", form.makerId);
      }
      if (form.wholesaleId) {
        coupons_where = coupons_where.where("wholesaleFlag", "==", true).where("wholesale_id", "==", form.wholesaleId);
      }
      if (form.registerId) {
        const registerStoreSnapshot = await firebase.firestore().collection("stores").where("register_id", "==", form.registerId).get()
        registerStoreSnapshot.forEach(doc => registerStores.push(doc.data()))
      }

      await coupons_where.get().then(
          function(result) {
              let tmp = [];
              result.forEach(function(doc) {
                  let PLUCode = parseInt(doc.data().jan_code, 10)
                  if (tmp[PLUCode] == undefined) {
                      tmp[PLUCode] = {}
                  }
                  tmp[PLUCode][doc.data().couponCode] = doc.data()
              });
              this.coupons = tmp;
          }.bind(this)
      );

      var usageResults = [];
      var usages_where = _usages;
      if (form.makerId != "" || form.wholesaleId != "") {
          var __coupons = await coupons_where.get();
          for (var c of __coupons.docs) {
              usages_where = _usages;
              usages_where = usages_where.where("id", "==", c.data().id);
              if (form.couponCode) {
                usages_where = usages_where.where("couponCode", "==", form.couponCode.padStart(6, '0'));
              }
              if (form.janCode) {
                usages_where = usages_where.where("PLUCode", "==", form.janCode);
              }
              if (form.supplierId) {
                usages_where = usages_where.where("companyCode", "==", form.supplierId);
              } else if (form.companyCode) {
                usages_where = usages_where.where("companyCode", "==", form.companyCode);
              }
              if (form.storeCode) {
                usages_where = usages_where.where("storeCode", "==", form.storeCode);
              }
              if (form.sort == 1) {
                  usages_where = usages_where.orderBy("usageTime", "desc");
                  if (form.endDate) {
                    usages_where = usages_where.startAt(parseInt(form.endDate.split("-").join("") + "235959", 10));
                  }
                  if (form.startDate) {
                    usages_where = usages_where.endAt(parseInt(form.startDate.split("-").join("") + "000000", 10));
                  }
              } else {
                  usages_where = usages_where.orderBy("usageTime", "asc");
                  if (form.startDate) {
                    usages_where = usages_where.startAt(parseInt(form.startDate.split("-").join("") + "000000", 10));
                  }
                  if (form.endDate) {
                    usages_where = usages_where.endAt(parseInt(form.endDate.split("-").join("") + "235959", 10));
                  }
              }
              let __usages = await usages_where.get();
              for (let u of __usages.docs) {
                let usageData = u.data()
                if (form.registerId) {
                  if (!registerStores.some(rs => rs.companyCode == parseInt(usageData.companyCode, 10) && rs.storeCode == parseInt(usageData.storeCode, 10))) {
                    continue
                  }
                }
                usageResults.push(usageData);
              }
          }
      } else {
          if (form.couponCode) {
              usages_where = usages_where.where("couponCode", "==", form.couponCode.padStart(6, '0'));
          }
          if (form.janCode) {
              usages_where = usages_where.where("PLUCode", "==", form.janCode);
          }
          if (form.supplierId) {
              usages_where = usages_where.where("companyCode", "==", form.supplierId);
          } else if (form.companyCode) {
            usages_where = usages_where.where("companyCode", "==", form.companyCode);
          }
          if (form.storeCode) {
            usages_where = usages_where.where("storeCode", "==", form.storeCode);
          }
          if (form.sort == 1) {
              usages_where = usages_where.orderBy("usageTime", "desc");
              if (form.endDate) {
                usages_where = usages_where.startAt(parseInt(form.endDate.split("-").join("") + "235959", 10));
              }
              if (form.startDate) {
                usages_where = usages_where.endAt(parseInt(form.startDate.split("-").join("") + "000000", 10));
              }
          } else {
              usages_where = usages_where.orderBy("usageTime", "asc");
              if (form.startDate) {
                usages_where = usages_where.startAt(parseInt(form.startDate.split("-").join("") + "000000", 10));
              }
              if (form.endDate) {
                usages_where = usages_where.endAt(parseInt(form.endDate.split("-").join("") + "235959", 10));
              }
          }
          let __usages = await usages_where.get();
          for (let u of __usages.docs) {
            let usageData = u.data()
            if (form.registerId) {
              if (!registerStores.some(rs => rs.companyCode == parseInt(usageData.companyCode, 10) && rs.storeCode == parseInt(usageData.storeCode, 10))) {
                continue
              }
            }
            usageResults.push(usageData);
          }
      }

      const users = this.users;
      const stores = this.stores;
      const makers = this.makers;
      const wholesales = this.wholesales;
      const coupons = this.coupons;
      const archiveCoupons = await firebase.firestore().collection("archivedCoupons").get();

      let csv = "";
      if (usageResults) {
        if (form.downloadType == 1) {
          csv = '\ufeff' + '小売コード,小売名,店舗コード,店舗名,メーカー名,卸名,クーポン名,表示規格名称,クーポン番号,JANコード,割引金額,使用枚数,使用日時'
          if (this.account.role != UserRole.DISTRIBUTIONS && this.account.role != UserRole.REGISTER) {
            csv += ',顧客ID'
          }
          csv += '\n'
          usageResults.sort((a, b) => {
            if (this.account.role == UserRole.MAKERS) {
              // 小売ブランド別
              if (a.companyCode > b.companyCode) return 1;
              if (a.companyCode < b.companyCode) return -1;

              // 店舗別
              if (a.storeCode > b.storeCode) return 1;
              if (a.storeCode < b.storeCode) return -1;
            } else if (this.account.role == UserRole.DISTRIBUTIONS) {
              // メーカー別
              if (this.getCouponMakerId(a) > this.getCouponMakerId(b)) return 1;
              if (this.getCouponMakerId(a) < this.getCouponMakerId(b)) return -1;

              // 店舗別
              if (a.storeCode > b.storeCode) return 1;
              if (a.storeCode < b.storeCode) return -1;
            } else if (this.account.role == UserRole.REGISTER) {
              // 小売ブランド別
              if (a.companyCode > b.companyCode) return 1;
              if (a.companyCode < b.companyCode) return -1;

              // メーカー別
              if (this.getCouponMakerId(a) > this.getCouponMakerId(b)) return 1;
              if (this.getCouponMakerId(a) < this.getCouponMakerId(b)) return -1;
            } else if (this.account.role == UserRole.WHOLESALE) {
              // メーカー別
              if (this.getCouponMakerId(a) > this.getCouponMakerId(b)) return 1;
              if (this.getCouponMakerId(a) < this.getCouponMakerId(b)) return -1;

              // 小売ブランド別
              if (a.companyCode > b.companyCode) return 1;
              if (a.companyCode < b.companyCode) return -1;

              // 店舗別
              if (a.storeCode > b.storeCode) return 1;
              if (a.storeCode < b.storeCode) return -1;
            }

            if (form.sort == 1) {
              if (a.usageTime > b.usageTime) return -1;
              if (a.usageTime < b.usageTime) return 1;
            } else {
              if (a.usageTime > b.usageTime) return 1;
              if (a.usageTime < b.usageTime) return -1;
            }
            return 0;
          }).forEach(u => {
            let coupon = this.getCoupon(u);
            // 小売ユーザーでなければ小売発行クーポンは出力しない
            if (coupon == null || !coupon.distributionFlag || this.account.role == UserRole.ADMIN || this.account.role == UserRole.DISTRIBUTIONS) {
              const userName = users[parseInt(u.companyCode, 10)] || ''
              const store = stores[parseInt(u.companyCode, 10)] 
              const storeName = store == null ? '' : (store[parseInt(u.storeCode, 10)] || '')
              var line = u.companyCode + "," +
                        userName + "," +
                        u.storeCode + "," +
                        storeName + "," +
                        (coupon == null ? "" : (makers[coupon["maker_id"]] || "")) + "," +
                        (coupon == null || !coupon["wholesaleFlag"] ? "" : wholesales[coupon["wholesale_id"]]) + "," +
                        (coupon == null ? "" : coupon["title"]) + "," +
                        (coupon == null ? "" : coupon["subTitle"]) + "," +
                        u.couponCode + "," +
                        u.PLUCode + "," +
                        u.couponPrice + "," +
                        u.purchaseQuantity + "," +
                        u.usageTime
              if (this.account.role != UserRole.DISTRIBUTIONS && this.account.role != UserRole.REGISTER) {
                line += "," + u.ocClientID
              }
              csv += line + '\n'
            }
          });
        } else {
          csv = '\ufeff' + '小売名,店舗名,メーカー名,卸名,クーポン名,単価,枚数,金額,手数料\n'
          let total = {};
          usageResults.forEach(function(u) {
            if (total[u.storeCode] == undefined) {
                total[u.storeCode] = {};
            }
            if (total[u.storeCode][u.PLUCode] == undefined) {
                total[u.storeCode][u.PLUCode] = {};
            }
            if (total[u.storeCode][u.PLUCode][u.couponCode] == undefined) {
                total[u.storeCode][u.PLUCode][u.couponCode] = {"couponPrice": 0, "purchaseQuantity": 0, "companyName": "", "storeName": "", "makerName": ""};
            }
            const userName = users[parseInt(u.companyCode, 10)] || ''
            const store = stores[parseInt(u.companyCode, 10)]
            const storeName = store == null ? '' : (store[parseInt(u.storeCode, 10)] || '')
            total[u.storeCode][u.PLUCode][u.couponCode]["couponPrice"] += parseInt(u.couponPrice, 10);
            total[u.storeCode][u.PLUCode][u.couponCode]["purchaseQuantity"] += parseInt(u.purchaseQuantity, 10);
            total[u.storeCode][u.PLUCode][u.couponCode]["companyName"] = userName;
            total[u.storeCode][u.PLUCode][u.couponCode]["storeName"] = storeName;

            let makerId = null;
            let wholesaleId = null;
            let wholesaleFlag = false;
            if (coupons[parseInt(u.PLUCode, 10)] != null) {
              if (coupons[parseInt(u.PLUCode, 10)][parseInt(u.couponCode, 10)] != null) {
                makerId = coupons[parseInt(u.PLUCode, 10)][parseInt(u.couponCode, 10)]["maker_id"];
                wholesaleId = coupons[parseInt(u.PLUCode, 10)][parseInt(u.couponCode, 10)]["wholesale_id"];
                wholesaleFlag = coupons[parseInt(u.PLUCode, 10)][parseInt(u.couponCode, 10)]["wholesaleFlag"];
              } else {
                const archiveCoupon = archiveCoupons.docs.find(d => d.data().jan_code == Number(u.PLUCode) && d.data().couponCode == Number(u.couponCode));
                if (archiveCoupon) {
                  makerId = archiveCoupon.data().maker_id;
                  wholesaleId = archiveCoupon.data().wholesale_id;
                  wholesaleFlag = archiveCoupon.data().wholesaleFlag;
                }
              }
            }

            if (makerId != null) {
              total[u.storeCode][u.PLUCode][u.couponCode]["makerName"] = makers[makerId];
            } else {
              total[u.storeCode][u.PLUCode][u.couponCode]["makerName"] = "";
            }
            if (wholesaleId != null && wholesaleFlag) {
              total[u.storeCode][u.PLUCode][u.couponCode]["wholesalesName"] = wholesales[wholesaleId];
            } else {
              total[u.storeCode][u.PLUCode][u.couponCode]["wholesalesName"] = "";
            }
          });
          for (var storeCode in total) {
            for (var PLUCode in total[storeCode]) {
              for (var couponCode in total[storeCode][PLUCode]) {
                let title = "";
                let discount = "";
                if (coupons[parseInt(PLUCode, 10)] != null) {
                  if (coupons[parseInt(PLUCode, 10)][parseInt(couponCode, 10)] != null) {
                    title = coupons[parseInt(PLUCode, 10)][parseInt(couponCode, 10)]["title"];
                    discount = coupons[parseInt(PLUCode, 10)][parseInt(couponCode, 10)]["discount"];
                  } else if (coupons[parseInt(PLUCode, 10)][parseInt(couponCode, 10)] == null) {
                    const archiveCoupon = archiveCoupons.docs.find(d => d.data().jan_code == Number(PLUCode) && d.data().couponCode == Number(couponCode));
                    if (archiveCoupon) {
                      title = archiveCoupon.data().title
                      discount = archiveCoupon.data().discount
                    }
                  }
                }
                var line = total[storeCode][PLUCode][couponCode]["companyName"] + "," +
                          total[storeCode][PLUCode][couponCode]["storeName"] + "," +
                          total[storeCode][PLUCode][couponCode]["makerName"] + "," +
                          total[storeCode][PLUCode][couponCode]["wholesalesName"] + "," +
                          title + "," +
                          discount + "," +
                          total[storeCode][PLUCode][couponCode]["purchaseQuantity"] + "," +
                          total[storeCode][PLUCode][couponCode]["couponPrice"] + "," +
                          total[storeCode][PLUCode][couponCode]["couponPrice"] * 0.04 + '\n'
                csv += line;
              }
            }
          }
        }
      } else {
        csv = '\ufeff' + '小売名,店舗名,メーカー名,卸名,クーポン名,単価,枚数,金額,手数料\n'
        let total = {};
        usageResults.forEach((u) => {
          let coupon = this.getCoupon(u);
          // 小売ユーザーでなければ小売発行クーポンは出力しない
          if (coupon == null || !coupon.distributionFlag || this.account.role == UserRole.ADMIN || this.account.role == UserRole.DISTRIBUTIONS) {
            if (total[u.storeCode] == undefined) {
              total[u.storeCode] = {};
            }
            if (total[u.storeCode][u.PLUCode] == undefined) {
              total[u.storeCode][u.PLUCode] = {};
            }
            if (total[u.storeCode][u.PLUCode][u.couponCode] == undefined) {
              total[u.storeCode][u.PLUCode][u.couponCode] = {"couponPrice": 0, "purchaseQuantity": 0, "companyName": "", "storeName": "", "makerName": ""};
            }
            const userName = users[parseInt(u.companyCode, 10)] || ''
            const store = stores[parseInt(u.companyCode, 10)]
            const storeName = store == null ? '' : (store[parseInt(u.storeCode, 10)] || '')
            total[u.storeCode][u.PLUCode][u.couponCode]["couponPrice"] += parseInt(u.couponPrice, 10);
            total[u.storeCode][u.PLUCode][u.couponCode]["purchaseQuantity"] += parseInt(u.purchaseQuantity, 10);
            total[u.storeCode][u.PLUCode][u.couponCode]["companyName"] = userName;
            total[u.storeCode][u.PLUCode][u.couponCode]["storeName"] = storeName;
            total[u.storeCode][u.PLUCode][u.couponCode]["makerName"] = makers[coupons[parseInt(u.PLUCode, 10)][parseInt(u.couponCode, 10)]["maker_id"]];
            if (coupons[parseInt(u.PLUCode, 10)][parseInt(u.couponCode, 10)]["wholesaleFlag"]) {
              total[u.storeCode][u.PLUCode][u.couponCode]["wholesalesName"] = wholesales[coupons[parseInt(u.PLUCode, 10)][parseInt(u.couponCode, 10)]["wholesale_id"]];
            } else {
              total[u.storeCode][u.PLUCode][u.couponCode]["wholesalesName"] = "";
            }
          }
        });
        for (let storeCode in total) {
          for (let PLUCode in total[storeCode]) {
            for (let couponCode in total[storeCode][PLUCode]) {
              let line = total[storeCode][PLUCode][couponCode]["companyName"] + "," +
                        total[storeCode][PLUCode][couponCode]["storeName"] + "," +
                        total[storeCode][PLUCode][couponCode]["makerName"] + "," +
                        total[storeCode][PLUCode][couponCode]["wholesalesName"] + "," +
                        coupons[parseInt(PLUCode, 10)][parseInt(couponCode, 10)]["title"] + "," +
                        coupons[parseInt(PLUCode, 10)][parseInt(couponCode, 10)]["discount"] + "," +
                        total[storeCode][PLUCode][couponCode]["purchaseQuantity"] + "," +
                        total[storeCode][PLUCode][couponCode]["couponPrice"] + "," +
                        total[storeCode][PLUCode][couponCode]["couponPrice"] * 0.04 + '\n'
              csv += line;
            }
          }
        }
      }
      let blob = new Blob([csv], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Result.csv'
      link.click()
    },
    getCoupon(u) {
      let coupon = null
      if (this.coupons[parseInt(u.PLUCode, 10)] != null && this.coupons[parseInt(u.PLUCode, 10)][parseInt(u.couponCode, 10)] != null) {
        coupon = this.coupons[parseInt(u.PLUCode, 10)][parseInt(u.couponCode, 10)]
      }
      return coupon;
    },
    getCouponMakerId(u) {
      let coupon = this.getCoupon(u);
      return (coupon == null ? "" : 
        (!this.makers[coupon["maker_id"]] ? "": this.makers[coupon["maker_id"]]));
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route: function() {
      this.init();
    }
  }
};
</script>
